'use client';

import { useEffect } from 'react';
import { useEffectOnce, useInterval } from 'react-use';
import { Time } from '@sapphire/duration';
import * as Sentry from '@sentry/nextjs';
import { useSession } from 'next-auth/react';
import { toast } from 'sonner';
import { useSelectedProjectStore } from '@/src/lib/stores/selected-project-store/use-selected-project.store';
export const APP_CURRENCY = '_APP_CURRENCY_';
export const APP_CURRENCY_DEFAULT = 'HNL';
export default function SessionUpdate() {
  const selectedProject = useSelectedProjectStore(state => state.selectedProject);
  const {
    data: session,
    update
  } = useSession();
  useInterval(async () => {
    try {
      if (update) {
        await update({
          force: true
        });
      }
    } catch (error) {
      console.error(error);
    }
  }, Time.Hour);
  useEffect(() => {
    if (update) {
      const visibilityHandler = async () => {
        update ? await update({
          force: true
        }) : null;
      };
      const handleStorage = (event: StorageEvent) => {
        if (event.key === 'reloadSignal') {
          toast.warning('Se ha cambiado el proyecto seleccionado, se recargarán tus pestañas.');
          window.location.reload();
        }
      };
      window.addEventListener('visibilitychange', visibilityHandler);
      window.addEventListener('storage', handleStorage);
      return () => {
        window.removeEventListener('visibilitychange', visibilityHandler);
        window.removeEventListener('storage', handleStorage);
      };
    }
  }, [update, session]);
  useEffect(() => {
    if (selectedProject) {}
    if (session?.user) {
      Sentry.setUser({
        email: session.user.email!,
        id: session.user.id,
        username: session.user.name!
      });
    } else {
      Sentry.setUser(null);
    }
  }, [session]);
  useEffectOnce(() => {
    (async () => {
      if (update) {
        await update({
          force: true
        });
      }
    })();
  });
  return null;
}