'use client';

import { Loader2Icon } from 'lucide-react';
import { useProjectSelectorStore } from '@/src/lib/stores/project-selector/project-selector-store';
import CompanyLogo from '@/components/shared/client/logos/company-logo';
import { RenderIf } from '@/components/shared/server/conditional/render-if';
interface LoadingSelectedProjectProps {
  withProjectKnown?: boolean;
}
export default function LoadingSelectedProject({
  withProjectKnown = true
}: LoadingSelectedProjectProps) {
  const isLoading = useProjectSelectorStore(state => state.isLoadingProject);
  const projectName = useProjectSelectorStore(state => state.loadingProjectName);
  if (!isLoading && withProjectKnown) return null;
  return <div className="fixed inset-0 z-[999] flex h-screen w-full items-center justify-center bg-black bg-opacity-80 lg:text-2xl" data-sentry-component="LoadingSelectedProject" data-sentry-source-file="loading-selected-project.tsx">
      <div className="flex w-full flex-col items-center rounded-lg p-8">
        <CompanyLogo forceWhite={true} data-sentry-element="CompanyLogo" data-sentry-source-file="loading-selected-project.tsx" />
        <Loader2Icon className="animate-spin text-primary" size={120} data-sentry-element="Loader2Icon" data-sentry-source-file="loading-selected-project.tsx" />
        <div className="mt-4 flex w-full flex-col items-center gap-y-3 text-center text-white">
          <p>
            Cargando proyecto<RenderIf condition={withProjectKnown} data-sentry-element="RenderIf" data-sentry-source-file="loading-selected-project.tsx">:</RenderIf>
          </p>
          <RenderIf condition={withProjectKnown} data-sentry-element="RenderIf" data-sentry-source-file="loading-selected-project.tsx">
            <p className="w-full max-w-[90%] text-center font-bold md:max-w-[70%] lg:max-w-[50%]">{projectName}</p>
          </RenderIf>
        </div>
      </div>
    </div>;
}