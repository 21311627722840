'use client';

import { useState } from 'react';
import { useRouter } from 'next/navigation';
import { NextUIProvider } from '@nextui-org/react';
import { registerLicense } from '@syncfusion/ej2-base';
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ReactQueryStreamedHydration } from '@tanstack/react-query-next-experimental';
import { SessionProvider } from 'next-auth/react';
import { ThemeProvider as NextThemesProvider } from 'next-themes';
import type { ReactNode } from 'react';
import GoogleAnalytics from '@/src/app/GoogleAnalytics';
import { ApiResponse } from '@/lib/types/api/common';
import SessionUpdate from '@/components/shared/client/auth/session';
import { ShowToastErrors } from '@/lib/utils/functions/show-errors';
export enum AuthErrorMessages {
  Forbidden = 'Usted no tiene permiso para ejecutar esta acción.',
  Unauthorized = 'Usted no está autorizado para ejecutar esta acción',
}
registerLicense(process.env.NEXT_PUBLIC_REACT_APP_SYNCFUSION_KEY as string);
export default function Providers({
  children
}: {
  children: ReactNode;
}) {
  const router = useRouter();
  const [queryClient] = useState(() => new QueryClient({
    mutationCache: new MutationCache({
      onSettled: async (data: unknown) => {
        if (data && typeof data === 'object' && 'type' in data && (data as any).type === 'application/pdf') return;
        if (data && typeof data === 'object') {
          const dataResponse = data as ApiResponse<{}>;
          const errMessage = AuthErrorMessages.Unauthorized.toLowerCase();
          if (!dataResponse?.succeeded && dataResponse?.message?.toLowerCase().includes(errMessage) && typeof window !== 'undefined') {
            router.push('/auth/sign-out');
          }
        }
      },
      onSuccess: async (data: unknown) => {
        if (data && typeof data === 'object' && 'type' in data && (data as any).type === 'application/pdf') return;
        if (data && typeof data === 'object') {
          const dataResponse = data as ApiResponse<any>;
          if (!dataResponse.succeeded && dataResponse.failed) {
            return ShowToastErrors({
              response: dataResponse
            });
          }
        }
      }
    }),
    queryCache: new QueryCache({
      onSettled: async (data: unknown) => {
        if (data && typeof data === 'object') {
          const dataResponse = data as ApiResponse<{}>;
          const errMessage = AuthErrorMessages.Unauthorized.toLowerCase();
          if (!dataResponse?.succeeded && dataResponse?.message?.toLowerCase().includes(errMessage) && typeof window !== 'undefined') {
            router.push('/auth/sign-out');
          }
        }
      }
    })
  }));
  return <NextUIProvider navigate={router.push} data-sentry-element="NextUIProvider" data-sentry-component="Providers" data-sentry-source-file="providers.tsx">
      <NextThemesProvider attribute={'class'} defaultTheme={'dark'} enableColorScheme={true} data-sentry-element="NextThemesProvider" data-sentry-source-file="providers.tsx">
        <SessionProvider data-sentry-element="SessionProvider" data-sentry-source-file="providers.tsx">
          <QueryClientProvider client={queryClient} data-sentry-element="QueryClientProvider" data-sentry-source-file="providers.tsx">
            <ReactQueryStreamedHydration data-sentry-element="ReactQueryStreamedHydration" data-sentry-source-file="providers.tsx">
              <GoogleAnalytics data-sentry-element="GoogleAnalytics" data-sentry-source-file="providers.tsx" />
              {children}
              <SessionUpdate data-sentry-element="SessionUpdate" data-sentry-source-file="providers.tsx" />
              <ReactQueryDevtools initialIsOpen={false} data-sentry-element="ReactQueryDevtools" data-sentry-source-file="providers.tsx" />
            </ReactQueryStreamedHydration>
          </QueryClientProvider>
        </SessionProvider>
      </NextThemesProvider>
    </NextUIProvider>;
}